"use client";

import {
  AWSlogo,
  AzurelogoFill,
  GoogleCloudlogoFill,
  Outpostlogo,
} from "@jengaicons/react";
import React from "react";

export function InfrastructureFlow() {
  const generateRandomSequence = () => {
    return Array(10)
      .fill(0)
      .map(() => Math.floor(Math.random() * 2));
  };

  const initialSequences = Array(120).fill(0).map(generateRandomSequence);

  return (
    <div
      className="relative flex flex-col items-center justify-center overflow-hidden"
      suppressHydrationWarning
    >
      <div className="relative flex flex-col items-center justify-center overflow-hidden px-4 py-8 lg:h-full lg:w-screen lg:flex-row lg:py-16">
        <div className="text-disabled absolute left-[50%] top-[100%] hidden w-24 translate-x-[-50%] select-none font-mono lg:block lg:w-[134px]">
          <div className="animate-scroll-bottom">
            <div className="scroll-content">
              {initialSequences.map((sequence, index) => (
                <div
                  key={index}
                  className="flex flex-row justify-center gap-1 text-3xl"
                >
                  {sequence.map((num, subIndex) => (
                    <span suppressHydrationWarning key={subIndex}>
                      {num}
                    </span>
                  ))}
                </div>
              ))}
              {initialSequences.map((sequence, index) => (
                <div
                  key={index}
                  className="flex flex-row justify-center gap-1 text-3xl"
                >
                  {sequence.map((num, subIndex) => (
                    <span suppressHydrationWarning key={subIndex}>
                      {num}
                    </span>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 left-[0%] z-[42] hidden lg:block">
          <div className="h-16 w-screen bg-gradient-to-t from-[#f9fafb] md:h-16 dark:from-[#111]"></div>
        </div>
        <div className="absolute left-[0%] top-0 z-[42] hidden lg:block">
          <div className="h-16 w-screen bg-gradient-to-b from-[#f9fafb] md:h-16 dark:from-[#111]"></div>
        </div>

        <div className="z-40 flex flex-row gap-6 lg:flex-col">
          <div className="bg-default flex h-[52px] w-[52px] flex-row rounded-lg border lg:w-[132px]">
            <div className="flex w-full items-center justify-center border-r">
              <AWSlogo />
            </div>
            <div className="hidden w-full items-center justify-center font-medium lg:flex">
              AWS
            </div>
          </div>
          <div className="bg-default flex h-[52px] w-[52px] flex-row rounded-lg border lg:w-[132px]">
            <div className="flex w-full items-center justify-center border-r">
              <AzurelogoFill />
            </div>
            <div className="hidden w-full items-center justify-center font-medium lg:flex">
              Azure
            </div>
          </div>
          <div className="bg-default flex h-[52px] w-[52px] flex-row rounded-lg border lg:w-[132px]">
            <div className="flex w-full items-center justify-center border-r">
              <GoogleCloudlogoFill />
            </div>
            <div className="hidden w-full items-center justify-center font-medium lg:flex">
              GCP
            </div>
          </div>
          <div className="bg-default flex h-[52px] w-[52px] flex-row items-center justify-center rounded-lg border p-1 text-[10px] font-medium lg:w-[132px] lg:text-[14px]">
            On Premise
          </div>
        </div>

        <div className="relative flex w-24 rotate-90 flex-col gap-3.5 lg:w-auto lg:rotate-0">
          <div className="absolute -top-[118px]">
            <svg
              width="314"
              height="94"
              viewBox="0 0 314 94"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="dark:hidden"
            >
              <path
                d="M0 1H79.2579C101.8 1 121.555 16.0839 127.492 37.8299L132.5 56.17C138.437 77.9161 158.192 93 180.734 93H314"
                stroke="url(#paint0_linear_5935_141036)"
                stroke-width="2"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_5935_141036"
                  x1="65.312"
                  y1="47"
                  x2="314"
                  y2="47"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#E4E4E7" />
                  <stop offset="0.64" stop-color="#7F7AFF" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              width="314"
              height="94"
              viewBox="0 0 314 94"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="hidden dark:block"
            >
              <path
                d="M0 1H79.2579C101.8 1 121.555 16.0839 127.492 37.8299L132.5 56.17C138.437 77.9161 158.192 93 180.734 93H314"
                stroke="url(#paint0_linear_5935_145202)"
                stroke-width="2"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_5935_145202"
                  x1="65.312"
                  y1="47"
                  x2="314"
                  y2="47"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#27272A" />
                  <stop offset="0.64" stop-color="#A297FF" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="absolute -top-10">
            <svg
              width="314"
              height="33"
              viewBox="0 0 314 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="dark:hidden"
            >
              <path
                d="M0 1H94.4595C103.733 1 112.824 3.57892 120.715 8.44852L146.813 24.5515C154.704 29.4211 163.795 32 173.068 32H314"
                stroke="url(#paint0_linear_5935_141038)"
                stroke-width="2"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_5935_141038"
                  x1="65.312"
                  y1="18"
                  x2="314"
                  y2="18"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#E4E4E7" />
                  <stop offset="0.64" stop-color="#7F7AFF" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              width="314"
              height="33"
              viewBox="0 0 314 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="hidden dark:block"
            >
              <path
                d="M0 1H94.4595C103.733 1 112.824 3.57892 120.715 8.44852L146.813 24.5515C154.704 29.4211 163.795 32 173.068 32H314"
                stroke="url(#paint0_linear_5935_145204)"
                stroke-width="2"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_5935_145204"
                  x1="65.312"
                  y1="18"
                  x2="314"
                  y2="18"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#27272A" />
                  <stop offset="0.64" stop-color="#A297FF" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="absolute -bottom-10">
            <svg
              width="314"
              height="33"
              viewBox="0 0 314 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="dark:hidden"
            >
              <path
                d="M0 32H94.4595C103.733 32 112.824 29.4211 120.715 24.5515L146.813 8.44852C154.704 3.57892 163.795 1 173.068 1H314"
                stroke="url(#paint0_linear_5935_141039)"
                stroke-width="2"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_5935_141039"
                  x1="65.312"
                  y1="15"
                  x2="314"
                  y2="15"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#E4E4E7" />
                  <stop offset="0.64" stop-color="#7F7AFF" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              width="314"
              height="33"
              viewBox="0 0 314 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="hidden dark:block"
            >
              <path
                d="M0 32H94.4595C103.733 32 112.824 29.4211 120.715 24.5515L146.813 8.44852C154.704 3.57892 163.795 1 173.068 1H314"
                stroke="url(#paint0_linear_5935_145205)"
                stroke-width="2"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_5935_145205"
                  x1="65.312"
                  y1="15"
                  x2="314"
                  y2="15"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#27272A" />
                  <stop offset="0.64" stop-color="#A297FF" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="absolute -bottom-[118px]">
            <svg
              width="314"
              height="94"
              viewBox="0 0 314 94"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="dark:hidden"
            >
              <path
                d="M0 93H79.2579C101.8 93 121.555 77.9161 127.492 56.1701L132.5 37.83C138.437 16.0839 158.192 1 180.734 1H314"
                stroke="url(#paint0_linear_5935_141037)"
                stroke-width="2"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_5935_141037"
                  x1="65.312"
                  y1="47"
                  x2="314"
                  y2="47"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#E4E4E7" />
                  <stop offset="0.64" stop-color="#7F7AFF" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              width="314"
              height="94"
              viewBox="0 0 314 94"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="hidden dark:block"
            >
              <path
                d="M0 93H79.2579C101.8 93 121.555 77.9161 127.492 56.1701L132.5 37.83C138.437 16.0839 158.192 1 180.734 1H314"
                stroke="url(#paint0_linear_5935_145203)"
                stroke-width="2"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_5935_145203"
                  x1="65.312"
                  y1="47"
                  x2="314"
                  y2="47"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#27272A" />
                  <stop offset="0.64" stop-color="#A297FF" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="w-[314px]" />
        </div>

        <div className="bg-default z-40 mt-48 flex h-24 w-24 items-center justify-center rounded-3xl border-2 border-[#A297FF] lg:mt-0 lg:h-[134px] lg:w-[134px]">
          <Outpostlogo className="size-12 lg:size-16" />
        </div>

        <div className="flex rotate-90 flex-col gap-3.5 lg:rotate-0 dark:hidden">
          <svg
            width="182"
            height="2"
            viewBox="0 0 182 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M182 1L6.30667e-06 1"
              stroke="url(#paint0_linear_5935_141040)"
              stroke-width="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5935_141040"
                x1="0"
                y1="0.5"
                x2="182"
                y2="0.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.2" stop-color="#7F7AFF" />
                <stop offset="1" stop-color="#E4E4E7" />
              </linearGradient>
            </defs>
          </svg>
          <svg
            width="182"
            height="2"
            viewBox="0 0 182 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M182 1L6.30667e-06 1"
              stroke="url(#paint0_linear_5935_141040)"
              stroke-width="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5935_141040"
                x1="0"
                y1="0.5"
                x2="182"
                y2="0.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.2" stop-color="#7F7AFF" />
                <stop offset="1" stop-color="#E4E4E7" />
              </linearGradient>
            </defs>
          </svg>

          <svg
            width="182"
            height="2"
            viewBox="0 0 182 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M182 1L6.30667e-06 1"
              stroke="url(#paint0_linear_5935_141040)"
              stroke-width="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5935_141040"
                x1="0"
                y1="0.5"
                x2="182"
                y2="0.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.2" stop-color="#7F7AFF" />
                <stop offset="1" stop-color="#E4E4E7" />
              </linearGradient>
            </defs>
          </svg>

          <svg
            width="182"
            height="2"
            viewBox="0 0 182 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M182 1L6.30667e-06 1"
              stroke="url(#paint0_linear_5935_141040)"
              stroke-width="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5935_141040"
                x1="0"
                y1="0.5"
                x2="182"
                y2="0.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.2" stop-color="#7F7AFF" />
                <stop offset="1" stop-color="#E4E4E7" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="hidden rotate-90 flex-col gap-3.5 lg:rotate-0 dark:flex">
          <svg
            width="182"
            height="2"
            viewBox="0 0 182 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M182 1L6.30667e-06 1"
              stroke="url(#paint0_linear_5935_145206)"
              stroke-width="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5935_145206"
                x1="0"
                y1="0.5"
                x2="182"
                y2="0.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.2" stop-color="#A297FF" />
                <stop offset="1" stop-color="#27272A" />
              </linearGradient>
            </defs>
          </svg>

          <svg
            width="182"
            height="2"
            viewBox="0 0 182 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M182 1L6.30667e-06 1"
              stroke="url(#paint0_linear_5935_145206)"
              stroke-width="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5935_145206"
                x1="0"
                y1="0.5"
                x2="182"
                y2="0.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.2" stop-color="#A297FF" />
                <stop offset="1" stop-color="#27272A" />
              </linearGradient>
            </defs>
          </svg>

          <svg
            width="182"
            height="2"
            viewBox="0 0 182 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M182 1L6.30667e-06 1"
              stroke="url(#paint0_linear_5935_145206)"
              stroke-width="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5935_145206"
                x1="0"
                y1="0.5"
                x2="182"
                y2="0.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.2" stop-color="#A297FF" />
                <stop offset="1" stop-color="#27272A" />
              </linearGradient>
            </defs>
          </svg>

          <svg
            width="182"
            height="2"
            viewBox="0 0 182 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M182 1L6.30667e-06 1"
              stroke="url(#paint0_linear_5935_145206)"
              stroke-width="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5935_145206"
                x1="0"
                y1="0.5"
                x2="182"
                y2="0.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.2" stop-color="#A297FF" />
                <stop offset="1" stop-color="#27272A" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <div className="bg-default z-40 mt-10 flex h-[277px] w-[264px] flex-col rounded-xl border-2 lg:mt-0">
          <div className="h-[60px] w-full border-b p-3">
            <p className="font-medium">A100-80GB </p>
            <p className="text-soft">outpost-cluster.onoutpost.com</p>
          </div>
          <div className="flex h-[90px] w-full flex-row border-b">
            <div className="flex w-full flex-col items-center justify-center border-r">
              <div className="flex flex-row items-center justify-around gap-3 pb-2">
                <p className="text-soft text-[12px]">AWS</p>
                <p className="text-soft text-[12px]">123456789</p>
              </div>
              <div>
                <svg
                  width="104"
                  height="40"
                  viewBox="0 0 104 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="hidden dark:block"
                >
                  <circle cx="4" cy="4" r="4" fill="#27272A" />
                  <circle cx="20" cy="4" r="4" fill="#27272A" />
                  <circle cx="36" cy="4" r="4" fill="#27272A" />
                  <circle cx="52" cy="4" r="4" fill="#27272A" />
                  <circle cx="68" cy="4" r="4" fill="#27272A" />
                  <circle cx="84" cy="4" r="4" fill="#27272A" />
                  <circle cx="100" cy="4" r="4" fill="#A297FF" />
                  <circle cx="4" cy="20" r="4" fill="#27272A" />
                  <circle cx="20" cy="20" r="4" fill="#27272A" />
                  <circle cx="36" cy="20" r="4" fill="#27272A" />
                  <circle cx="52" cy="20" r="4" fill="#A297FF" />
                  <circle cx="68" cy="20" r="4" fill="#A297FF" />
                  <circle cx="84" cy="20" r="4" fill="#A297FF" />
                  <circle cx="100" cy="20" r="4" fill="#A297FF" />
                  <circle cx="4" cy="36" r="4" fill="#27272A" />
                  <circle cx="20" cy="36" r="4" fill="#27272A" />
                  <circle cx="36" cy="36" r="4" fill="#A297FF" />
                  <circle cx="52" cy="36" r="4" fill="#A297FF" />
                  <circle cx="68" cy="36" r="4" fill="#A297FF" />
                  <circle cx="84" cy="36" r="4" fill="#A297FF" />
                  <circle cx="100" cy="36" r="4" fill="#A297FF" />
                </svg>
                <svg
                  width="104"
                  height="40"
                  viewBox="0 0 104 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="dark:hidden"
                >
                  <circle cx="4" cy="4" r="4" fill="#E4E4E7" />
                  <circle cx="20" cy="4" r="4" fill="#E4E4E7" />
                  <circle cx="36" cy="4" r="4" fill="#E4E4E7" />
                  <circle cx="52" cy="4" r="4" fill="#E4E4E7" />
                  <circle cx="68" cy="4" r="4" fill="#E4E4E7" />
                  <circle cx="84" cy="4" r="4" fill="#E4E4E7" />
                  <circle cx="100" cy="4" r="4" fill="#7F7AFF" />
                  <circle cx="4" cy="20" r="4" fill="#E4E4E7" />
                  <circle cx="20" cy="20" r="4" fill="#E4E4E7" />
                  <circle cx="36" cy="20" r="4" fill="#E4E4E7" />
                  <circle cx="52" cy="20" r="4" fill="#7F7AFF" />
                  <circle cx="68" cy="20" r="4" fill="#7F7AFF" />
                  <circle cx="84" cy="20" r="4" fill="#7F7AFF" />
                  <circle cx="100" cy="20" r="4" fill="#7F7AFF" />
                  <circle cx="4" cy="36" r="4" fill="#E4E4E7" />
                  <circle cx="20" cy="36" r="4" fill="#E4E4E7" />
                  <circle cx="36" cy="36" r="4" fill="#7F7AFF" />
                  <circle cx="52" cy="36" r="4" fill="#7F7AFF" />
                  <circle cx="68" cy="36" r="4" fill="#7F7AFF" />
                  <circle cx="84" cy="36" r="4" fill="#7F7AFF" />
                  <circle cx="100" cy="36" r="4" fill="#7F7AFF" />
                </svg>
              </div>
            </div>
            <div className="flex w-full flex-col items-center justify-center">
              <div className="flex flex-row items-center justify-around gap-3 pb-2">
                <p className="text-soft text-[12px]">Azure</p>
                <p className="text-soft text-[12px]">123456789</p>
              </div>
              <div>
                <svg
                  width="104"
                  height="40"
                  viewBox="0 0 104 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="hidden dark:block"
                >
                  <circle cx="4" cy="4" r="4" fill="#27272A" />
                  <circle cx="20" cy="4" r="4" fill="#27272A" />
                  <circle cx="36" cy="4" r="4" fill="#27272A" />
                  <circle cx="52" cy="4" r="4" fill="#27272A" />
                  <circle cx="68" cy="4" r="4" fill="#27272A" />
                  <circle cx="84" cy="4" r="4" fill="#27272A" />
                  <circle cx="100" cy="4" r="4" fill="#27272A" />
                  <circle cx="4" cy="20" r="4" fill="#A297FF" />
                  <circle cx="20" cy="20" r="4" fill="#A297FF" />
                  <circle cx="36" cy="20" r="4" fill="#A297FF" />
                  <circle cx="52" cy="20" r="4" fill="#A297FF" />
                  <circle cx="68" cy="20" r="4" fill="#A297FF" />
                  <circle cx="84" cy="20" r="4" fill="#A297FF" />
                  <circle cx="100" cy="20" r="4" fill="#A297FF" />
                  <circle cx="4" cy="36" r="4" fill="#A297FF" />
                  <circle cx="20" cy="36" r="4" fill="#A297FF" />
                  <circle cx="36" cy="36" r="4" fill="#A297FF" />
                  <circle cx="52" cy="36" r="4" fill="#A297FF" />
                  <circle cx="68" cy="36" r="4" fill="#A297FF" />
                  <circle cx="84" cy="36" r="4" fill="#A297FF" />
                  <circle cx="100" cy="36" r="4" fill="#A297FF" />
                </svg>
                <svg
                  width="104"
                  height="40"
                  viewBox="0 0 104 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="dark:hidden"
                >
                  <circle cx="4" cy="4" r="4" fill="#E4E4E7" />
                  <circle cx="20" cy="4" r="4" fill="#E4E4E7" />
                  <circle cx="36" cy="4" r="4" fill="#E4E4E7" />
                  <circle cx="52" cy="4" r="4" fill="#E4E4E7" />
                  <circle cx="68" cy="4" r="4" fill="#E4E4E7" />
                  <circle cx="84" cy="4" r="4" fill="#E4E4E7" />
                  <circle cx="100" cy="4" r="4" fill="#E4E4E7" />
                  <circle cx="4" cy="20" r="4" fill="#7F7AFF" />
                  <circle cx="20" cy="20" r="4" fill="#7F7AFF" />
                  <circle cx="36" cy="20" r="4" fill="#7F7AFF" />
                  <circle cx="52" cy="20" r="4" fill="#7F7AFF" />
                  <circle cx="68" cy="20" r="4" fill="#7F7AFF" />
                  <circle cx="84" cy="20" r="4" fill="#7F7AFF" />
                  <circle cx="100" cy="20" r="4" fill="#7F7AFF" />
                  <circle cx="4" cy="36" r="4" fill="#7F7AFF" />
                  <circle cx="20" cy="36" r="4" fill="#7F7AFF" />
                  <circle cx="36" cy="36" r="4" fill="#7F7AFF" />
                  <circle cx="52" cy="36" r="4" fill="#7F7AFF" />
                  <circle cx="68" cy="36" r="4" fill="#7F7AFF" />
                  <circle cx="84" cy="36" r="4" fill="#7F7AFF" />
                  <circle cx="100" cy="36" r="4" fill="#7F7AFF" />
                </svg>
              </div>
            </div>
          </div>
          <div className="flex h-[122px] flex-col items-center justify-center p-3">
            <div className="flex w-full flex-row items-center justify-between gap-3 pb-2">
              <p className="text-soft text-[12px]">GCP</p>
              <p className="text-soft text-[12px]">123456789</p>
            </div>
            <div>
              <svg
                width="240"
                height="72"
                viewBox="0 0 240 72"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="hidden dark:block"
              >
                <circle cx="4" cy="4" r="4" fill="#27272A" />
                <circle cx="20.5713" cy="4" r="4" fill="#27272A" />
                <circle cx="37.1431" cy="4" r="4" fill="#27272A" />
                <circle cx="53.7144" cy="4" r="4" fill="#27272A" />
                <circle cx="70.2856" cy="4" r="4" fill="#27272A" />
                <circle cx="86.8569" cy="4" r="4" fill="#27272A" />
                <circle cx="103.429" cy="4" r="4" fill="#27272A" />
                <circle cx="120" cy="4" r="4" fill="#27272A" />
                <circle cx="136.571" cy="4" r="4" fill="#27272A" />
                <circle cx="153.143" cy="4" r="4" fill="#27272A" />
                <circle cx="169.714" cy="4" r="4" fill="#27272A" />
                <circle cx="186.286" cy="4" r="4" fill="#27272A" />
                <circle cx="202.857" cy="4" r="4" fill="#27272A" />
                <circle cx="219.429" cy="4" r="4" fill="#27272A" />
                <circle cx="236" cy="4" r="4" fill="#A297FF" />
                <circle cx="4" cy="20" r="4" fill="#27272A" />
                <circle cx="20.5713" cy="20" r="4" fill="#27272A" />
                <circle cx="37.1431" cy="20" r="4" fill="#27272A" />
                <circle cx="53.7144" cy="20" r="4" fill="#27272A" />
                <circle cx="70.2856" cy="20" r="4" fill="#27272A" />
                <circle cx="86.8569" cy="20" r="4" fill="#27272A" />
                <circle cx="103.429" cy="20" r="4" fill="#27272A" />
                <circle cx="120" cy="20" r="4" fill="#27272A" />
                <circle cx="136.571" cy="20" r="4" fill="#27272A" />
                <circle cx="153.143" cy="20" r="4" fill="#27272A" />
                <circle cx="169.714" cy="20" r="4" fill="#27272A" />
                <circle cx="186.286" cy="20" r="4" fill="#27272A" />
                <circle cx="202.857" cy="20" r="4" fill="#27272A" />
                <circle cx="219.429" cy="20" r="4" fill="#A297FF" />
                <circle cx="236" cy="20" r="4" fill="#A297FF" />
                <circle cx="4" cy="36" r="4" fill="#27272A" />
                <circle cx="20.5713" cy="36" r="4" fill="#27272A" />
                <circle cx="37.1431" cy="36" r="4" fill="#27272A" />
                <circle cx="53.7144" cy="36" r="4" fill="#27272A" />
                <circle cx="70.2856" cy="36" r="4" fill="#27272A" />
                <circle cx="86.8569" cy="36" r="4" fill="#27272A" />
                <circle cx="103.429" cy="36" r="4" fill="#27272A" />
                <circle cx="120" cy="36" r="4" fill="#27272A" />
                <circle cx="136.571" cy="36" r="4" fill="#27272A" />
                <circle cx="153.143" cy="36" r="4" fill="#27272A" />
                <circle cx="169.714" cy="36" r="4" fill="#27272A" />
                <circle cx="186.286" cy="36" r="4" fill="#27272A" />
                <circle cx="202.857" cy="36" r="4" fill="#A297FF" />
                <circle cx="219.429" cy="36" r="4" fill="#A297FF" />
                <circle cx="236" cy="36" r="4" fill="#A297FF" />
                <circle cx="4" cy="52" r="4" fill="#A297FF" />
                <circle cx="20.5713" cy="52" r="4" fill="#A297FF" />
                <circle cx="37.1431" cy="52" r="4" fill="#A297FF" />
                <circle cx="53.7144" cy="52" r="4" fill="#A297FF" />
                <circle cx="70.2856" cy="52" r="4" fill="#A297FF" />
                <circle cx="86.8569" cy="52" r="4" fill="#A297FF" />
                <circle cx="103.429" cy="52" r="4" fill="#A297FF" />
                <circle cx="120" cy="52" r="4" fill="#A297FF" />
                <circle cx="136.571" cy="52" r="4" fill="#A297FF" />
                <circle cx="153.143" cy="52" r="4" fill="#A297FF" />
                <circle cx="169.714" cy="52" r="4" fill="#A297FF" />
                <circle cx="186.286" cy="52" r="4" fill="#A297FF" />
                <circle cx="202.857" cy="52" r="4" fill="#A297FF" />
                <circle cx="219.429" cy="52" r="4" fill="#A297FF" />
                <circle cx="236" cy="52" r="4" fill="#A297FF" />
                <circle cx="4" cy="68" r="4" fill="#A297FF" />
                <circle cx="20.5713" cy="68" r="4" fill="#A297FF" />
                <circle cx="37.1431" cy="68" r="4" fill="#A297FF" />
                <circle cx="53.7144" cy="68" r="4" fill="#A297FF" />
                <circle cx="70.2856" cy="68" r="4" fill="#A297FF" />
                <circle cx="86.8569" cy="68" r="4" fill="#A297FF" />
                <circle cx="103.429" cy="68" r="4" fill="#A297FF" />
                <circle cx="120" cy="68" r="4" fill="#A297FF" />
                <circle cx="136.571" cy="68" r="4" fill="#A297FF" />
                <circle cx="153.143" cy="68" r="4" fill="#A297FF" />
                <circle cx="169.714" cy="68" r="4" fill="#A297FF" />
                <circle cx="186.286" cy="68" r="4" fill="#A297FF" />
                <circle cx="202.857" cy="68" r="4" fill="#A297FF" />
                <circle cx="219.429" cy="68" r="4" fill="#A297FF" />
                <circle cx="236" cy="68" r="4" fill="#A297FF" />
              </svg>
              <svg
                width="240"
                height="72"
                viewBox="0 0 240 72"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="dark:hidden"
              >
                <circle cx="4" cy="4" r="4" fill="#E4E4E7" />
                <circle cx="20.5713" cy="4" r="4" fill="#E4E4E7" />
                <circle cx="37.1431" cy="4" r="4" fill="#E4E4E7" />
                <circle cx="53.7144" cy="4" r="4" fill="#E4E4E7" />
                <circle cx="70.2856" cy="4" r="4" fill="#E4E4E7" />
                <circle cx="86.8569" cy="4" r="4" fill="#E4E4E7" />
                <circle cx="103.429" cy="4" r="4" fill="#E4E4E7" />
                <circle cx="120" cy="4" r="4" fill="#E4E4E7" />
                <circle cx="136.571" cy="4" r="4" fill="#E4E4E7" />
                <circle cx="153.143" cy="4" r="4" fill="#E4E4E7" />
                <circle cx="169.714" cy="4" r="4" fill="#E4E4E7" />
                <circle cx="186.286" cy="4" r="4" fill="#E4E4E7" />
                <circle cx="202.857" cy="4" r="4" fill="#E4E4E7" />
                <circle cx="219.429" cy="4" r="4" fill="#E4E4E7" />
                <circle cx="236" cy="4" r="4" fill="#7F7AFF" />
                <circle cx="4" cy="20" r="4" fill="#E4E4E7" />
                <circle cx="20.5713" cy="20" r="4" fill="#E4E4E7" />
                <circle cx="37.1431" cy="20" r="4" fill="#E4E4E7" />
                <circle cx="53.7144" cy="20" r="4" fill="#E4E4E7" />
                <circle cx="70.2856" cy="20" r="4" fill="#E4E4E7" />
                <circle cx="86.8569" cy="20" r="4" fill="#E4E4E7" />
                <circle cx="103.429" cy="20" r="4" fill="#E4E4E7" />
                <circle cx="120" cy="20" r="4" fill="#E4E4E7" />
                <circle cx="136.571" cy="20" r="4" fill="#E4E4E7" />
                <circle cx="153.143" cy="20" r="4" fill="#E4E4E7" />
                <circle cx="169.714" cy="20" r="4" fill="#E4E4E7" />
                <circle cx="186.286" cy="20" r="4" fill="#E4E4E7" />
                <circle cx="202.857" cy="20" r="4" fill="#E4E4E7" />
                <circle cx="219.429" cy="20" r="4" fill="#7F7AFF" />
                <circle cx="236" cy="20" r="4" fill="#7F7AFF" />
                <circle cx="4" cy="36" r="4" fill="#E4E4E7" />
                <circle cx="20.5713" cy="36" r="4" fill="#E4E4E7" />
                <circle cx="37.1431" cy="36" r="4" fill="#E4E4E7" />
                <circle cx="53.7144" cy="36" r="4" fill="#E4E4E7" />
                <circle cx="70.2856" cy="36" r="4" fill="#E4E4E7" />
                <circle cx="86.8569" cy="36" r="4" fill="#E4E4E7" />
                <circle cx="103.429" cy="36" r="4" fill="#E4E4E7" />
                <circle cx="120" cy="36" r="4" fill="#E4E4E7" />
                <circle cx="136.571" cy="36" r="4" fill="#E4E4E7" />
                <circle cx="153.143" cy="36" r="4" fill="#E4E4E7" />
                <circle cx="169.714" cy="36" r="4" fill="#E4E4E7" />
                <circle cx="186.286" cy="36" r="4" fill="#E4E4E7" />
                <circle cx="202.857" cy="36" r="4" fill="#7F7AFF" />
                <circle cx="219.429" cy="36" r="4" fill="#7F7AFF" />
                <circle cx="236" cy="36" r="4" fill="#7F7AFF" />
                <circle cx="4" cy="52" r="4" fill="#7F7AFF" />
                <circle cx="20.5713" cy="52" r="4" fill="#7F7AFF" />
                <circle cx="37.1431" cy="52" r="4" fill="#7F7AFF" />
                <circle cx="53.7144" cy="52" r="4" fill="#7F7AFF" />
                <circle cx="70.2856" cy="52" r="4" fill="#7F7AFF" />
                <circle cx="86.8569" cy="52" r="4" fill="#7F7AFF" />
                <circle cx="103.429" cy="52" r="4" fill="#7F7AFF" />
                <circle cx="120" cy="52" r="4" fill="#7F7AFF" />
                <circle cx="136.571" cy="52" r="4" fill="#7F7AFF" />
                <circle cx="153.143" cy="52" r="4" fill="#7F7AFF" />
                <circle cx="169.714" cy="52" r="4" fill="#7F7AFF" />
                <circle cx="186.286" cy="52" r="4" fill="#7F7AFF" />
                <circle cx="202.857" cy="52" r="4" fill="#7F7AFF" />
                <circle cx="219.429" cy="52" r="4" fill="#7F7AFF" />
                <circle cx="236" cy="52" r="4" fill="#7F7AFF" />
                <circle cx="4" cy="68" r="4" fill="#7F7AFF" />
                <circle cx="20.5713" cy="68" r="4" fill="#7F7AFF" />
                <circle cx="37.1431" cy="68" r="4" fill="#7F7AFF" />
                <circle cx="53.7144" cy="68" r="4" fill="#7F7AFF" />
                <circle cx="70.2856" cy="68" r="4" fill="#7F7AFF" />
                <circle cx="86.8569" cy="68" r="4" fill="#7F7AFF" />
                <circle cx="103.429" cy="68" r="4" fill="#7F7AFF" />
                <circle cx="120" cy="68" r="4" fill="#7F7AFF" />
                <circle cx="136.571" cy="68" r="4" fill="#7F7AFF" />
                <circle cx="153.143" cy="68" r="4" fill="#7F7AFF" />
                <circle cx="169.714" cy="68" r="4" fill="#7F7AFF" />
                <circle cx="186.286" cy="68" r="4" fill="#7F7AFF" />
                <circle cx="202.857" cy="68" r="4" fill="#7F7AFF" />
                <circle cx="219.429" cy="68" r="4" fill="#7F7AFF" />
                <circle cx="236" cy="68" r="4" fill="#7F7AFF" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
