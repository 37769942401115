"use client";

import { FlexibleNodepool } from "@jengaicons/react";
import { Row } from "@primitives/layout";
import { Text } from "@primitives/text";
import { useMotionValueEvent, useScroll } from "framer-motion";
import { useRef, useState } from "react";

import { Section, SectionBody } from "../../sections";
import { Datasets } from "./datasets";
import { MLPortfolio } from "./ml-portfolio";
import { Models } from "./models";

export function OutpostHub() {
  const [visible, setVisible] = useState<"models" | "datasets" | "auto-ml">(
    "models",
  );
  const ref = useRef(null);

  const { scrollYProgress } = useScroll({
    target: ref,
  });

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (latest < 0.36) {
      setVisible("models");
    } else if (latest < 0.99 && latest) {
      setVisible("datasets");
    } else {
      setVisible("auto-ml");
    }
  });

  return (
    <Section ref={ref} className="border-b-0">
      <SectionBody className="grid divide-y md:grid-cols-[1fr_482px] md:divide-x md:divide-y-0 lg:grid-cols-[1fr_692px]">
        <div className="top-16 flex flex-col px-4 py-8 md:sticky md:h-[400px] md:px-16 md:py-24">
          <Row className="text-soft items-center gap-2">
            <FlexibleNodepool size={20} />
            <Text variant="headingMd" color="soft" weight="semibold">
              Hub
            </Text>
          </Row>
          <Text variant="heading3Xl" weight="bold" className="mt-3">
            Host your
          </Text>
          {visible === "models" ? (
            <>
              <div>
                <Text color="primary" variant="heading3Xl" weight="bold">
                  Models
                </Text>
              </div>

              <Text className="mt-5" variant="bodyXl" color="soft">
                for simple storage, discovery and collaboration
              </Text>
            </>
          ) : visible === "datasets" ? (
            <>
              <div>
                <Text color="primary" variant="heading3Xl" weight="bold">
                  Datasets
                </Text>
              </div>

              <Text className="mt-5" variant="bodyXl" color="soft">
                for simple storage, discovery and collaboration
              </Text>
            </>
          ) : (
            <>
              <div>
                <Text color="primary" variant="heading3Xl" weight="bold">
                  ML Portfolio
                </Text>
              </div>

              <Text className="mt-5" variant="bodyXl" color="soft">
                for simple storage, discovery and collaboration
              </Text>
            </>
          )}
        </div>
        <div className="flex flex-col divide-y overflow-hidden">
          <Models />
          <Datasets />
          <MLPortfolio />
        </div>
      </SectionBody>
    </Section>
  );
}
