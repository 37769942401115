"use client";

import { ChevronDown, Folder, GitBranchFill, Search } from "@jengaicons/react";
import { Badge } from "@primitives/badge";
import { Button } from "@primitives/button";
import { Separator } from "@primitives/generic";
import { Input } from "@primitives/input";
import { Column, Row } from "@primitives/layout";
import { Text } from "@primitives/text";
import { motion } from "framer-motion";

const modelBadges = [
  "Text-to-Image",
  "Diffusers",
  "ONNX",
  "StableDiffusionXLPipeline",
  "Inference Endpoints",
  "lora",
];
export function Models() {
  return (
    <div className="pl-4 pt-8 md:pl-16 md:pt-16">
      <Text
        color="soft"
        className="mb-8 max-w-[600px] md:mb-16"
        variant="bodyXl"
      >
        <Text color="default" as="span" variant="bodyXl" weight="semibold">
          Models.{" "}
        </Text>
        A place for sharing and discovering trained machine learning models
        ready-to-deploy and fine-tune.
      </Text>

      <motion.div
        initial={{
          translateX: 100,
        }}
        style={{
          opacity: 0,
        }}
        whileInView={{
          translateX: 0,
          opacity: 1,
        }}
        transition={{
          duration: 0.5,
        }}
        className="h-[404px] overflow-hidden"
      >
        <div className="bg-default rounded-lg rounded-r-none border border-r-0 p-10 pr-0">
          <Row className="items-center gap-2">
            <Text variant="heading2Xl" className="whitespace-nowrap">
              sdxl-simpsons-charcters
            </Text>
            <Badge size="sm" variant="primary-subdued">
              Public
            </Badge>
          </Row>
          <Row className="mt-4 gap-1">
            {modelBadges.map((i) => (
              <Badge
                size="sm"
                variant="outline"
                className="whitespace-nowrap rounded-md"
                key={i}
              >
                {i}
              </Badge>
            ))}
          </Row>
          <Separator className="my-7" direction="horizontal" />
          <Row className="gap-2">
            <Input containerStyles="w-[300px] shrink-0" prefix={<Search />} />
            <Button
              className="gap-2"
              variant="outline"
              prefix={<GitBranchFill />}
              suffix={<ChevronDown />}
            >
              Main
            </Button>
          </Row>

          <Column className="bg-default mt-5 divide-y overflow-hidden rounded-lg rounded-r-none border border-r-0">
            <Row className="bg-subdued gap-2 p-5">
              <div className="bg-primary size-5 rounded-full"></div>
              <Text>niva</Text>
              <Text>Update config.json (#101)</Text>
            </Row>
            <Row className="items-center p-5">
              <Row className="items-center gap-2">
                <Folder size={16} />
                <Text weight="medium" className="w-40">
                  Scheduler
                </Text>
              </Row>
              <Text color="soft" className="whitespace-nowrap">
                added support for abort and chat completion types
              </Text>
            </Row>
            <Row className="p-5">
              <Row className="items-center gap-2">
                <Folder size={16} />
                <Text weight="medium" className="w-40">
                  text_encoder
                </Text>
              </Row>
              <Text color="soft" className="whitespace-nowrap">
                added support for abort and chat completion types
              </Text>
            </Row>
          </Column>
        </div>
      </motion.div>
    </div>
  );
}
