"use client";

import { Text } from "@primitives/text";

import { Section, SectionBody } from "../sections";
import { OpenSourceBadges } from "./open-source-badges";

export function OpenSourceModels() {
  return (
    <Section className="border-b">
      <SectionBody className="relative flex w-full flex-col overflow-hidden lg:h-[380px] lg:flex-row">
        <div className="flex h-full shrink-0 items-center p-4 py-8 pr-0 md:w-[360px] md:p-16 xl:w-[460px]">
          <Text variant="heading3Xl" weight="bold">
            Choose <br /> from leading <br /> open-source models
          </Text>
        </div>

        <OpenSourceBadges />
      </SectionBody>
    </Section>
  );
}
