import { Text } from "@primitives/text";
import Image from "next/image";

import Marquee from "./marquee";

const models = [
  { icon: "/website/meta-logo.svg", title: "Llama-3 70B Instruct" },
  { icon: "/website/google-logo.svg", title: "flan-t5-large" },
  {
    icon: "/website/stability-logo.webp",
    title: "stable-diffusion-3-medium",
  },
  { icon: "/website/microsoft-logo.svg", title: "DialoGPT-large" },
  { icon: "/website/meta-logo.svg", title: "Llama-3.1-8B-Instruct" },
  { icon: "/website/google-logo.svg", title: "t5-v1_1-large" },
  { icon: "/website/runway-logo.webp", title: "stable-diffusion-v1-5" },
  { icon: "/website/microsoft-logo.svg", title: "deberta-v2-xlarge" },
  { icon: "/website/stability-logo.webp", title: "stable-diffusion-2-1" },
  { icon: "/website/huggingface-logo.svg", title: "CodeGen-6B-multi" },
  { icon: "/website/meta-logo.svg", title: "Llama-3 8B Instruct" },
  { icon: "/website/openai-logo.webp", title: "whisper-large-v2" },
  { icon: "/website/dbrx-logo.svg", title: "DBRX Instruct" },
  { icon: "/website/meta-logo.svg", title: "Llama-3.1-8B-Chinese-Chat" },
  { icon: "/website/mixtral-logo.svg", title: "Mixtral-8x7B-Instruct" },
  { icon: "/website/mixtral-logo.svg", title: "Mistral-7B" },
  { icon: "/website/meta-logo.svg", title: "Meta-Llama-3.1-70B-Instruct" },
];

const third = Math.ceil(models.length / 3);

const models1 = models.slice(0, third);
const models2 = models.slice(third, third * 2);
const models3 = models.slice(third * 2);
export function OpenSourceBadges() {
  return (
    <div className="relative flex h-[380px] w-full flex-row items-center justify-center overflow-hidden">
      <div className="absolute bottom-0 right-0 z-[42]">
        <div className="h-24 w-screen bg-gradient-to-t from-[#f9fafb] md:h-48 dark:from-[#0F0F11]"></div>
      </div>

      <div className="absolute right-0 top-0 z-[42]">
        <div className="h-24 w-screen bg-gradient-to-b from-[#f9fafb] md:h-48 dark:from-[#0F0F11]"></div>
      </div>
      <Marquee pauseOnHover vertical className="flex [--duration:20s]">
        {models1.map((model) => (
          <ModelCard key={model.title} {...model} />
        ))}
      </Marquee>
      <Marquee reverse pauseOnHover vertical className="flex [--duration:20s]">
        {models2.map((model) => (
          <ModelCard key={model.title} {...model} />
        ))}
      </Marquee>
      <Marquee
        pauseOnHover
        vertical
        className="hidden [--duration:20s] sm:flex"
      >
        {models3.map((model) => (
          <ModelCard key={model.title} {...model} />
        ))}
      </Marquee>
    </div>
  );
}

function ModelCard(props: { icon: string; title: string }) {
  return (
    <div className="bg-default flex h-[108px] w-[118px] select-none flex-col items-start justify-center gap-2 overflow-hidden rounded-lg border px-4 md:h-[64px] md:w-[196px] md:flex-row md:items-center md:justify-start">
      <div className="h-6 w-6">
        <Image
          src={props.icon}
          alt={props.title}
          width={24}
          height={24}
          className="rounded"
        />
      </div>
      <Text
        variant="bodySm"
        weight="semibold"
        className="overflow-hidden text-wrap"
      >
        {props.title}
      </Text>
    </div>
  );
}
