import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

const badgeVariants = cva(
  "focus:ring-focus inline-flex max-w-max items-center rounded-full border px-2.5  py-0.5 transition-colors focus:outline-none focus:ring-2 ",
  {
    variants: {
      variant: {
        default: "bg-primary text-default border-transparent",
        "primary-subdued": "bg-primary-subdued  border-primary text-primary",
        primary: "bg-primary  border-primary text-on-primary",
        success: "bg-success-subdued border-success text-success",
        critical: "bg-critical-subdued border-critical text-critical",
        inactive: "bg-default border text-disabled",
        warning: "bg-warning-subdued border-warning text-warning",
        outline: "text-default bg-default",
        secondary: "bg-secondary text-[#f2f2f2] dark:text-[#000]",
      },
      size: {
        base: "text-bodyMd  leading-bodyMd",
        sm: "text-bodySm  leading-bodySm px-2",
      },
    },
    defaultVariants: {
      variant: "outline",
      size: "base",
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className = "", variant, size, ...props }: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant, size }), className)}
      {...props}
    />
  );
}

export { Badge, badgeVariants };
