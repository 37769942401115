"use client";

import { cn } from "@/lib/utils";
import * as SelectPrimitive from "@radix-ui/react-select";
import * as React from "react";

import { ScrollArea } from "./scrollarea";

const Select: React.FC<
  SelectPrimitive.SelectProps & {
    errorMessage?: string;
  }
> = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

interface TriggerProps
  extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> {}

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  TriggerProps
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    asChild
    ref={ref}
    className={cn(
      "bg-default text-bodyMd data-[state='open']:bg-hovered placeholder:text-disabled flex h-9 w-full items-center justify-between rounded-lg border px-3 py-2 focus:outline-none",
      className,
    )}
    {...props}
  >
    {children}
  </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = "popper", ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        "bg-default text-default shadow-0.25 animate-in fade-in-80 relative z-[1000010] min-w-32 overflow-hidden rounded-lg border font-normal",
        position === "popper" && "translate-y-1",
        className,
      )}
      position={position}
      {...props}
    >
      <SelectPrimitive.Viewport
        className={cn(
          "space-y-1 p-1",
          position === "popper" &&
            "w-full min-w-[var(--radix-select-trigger-width)]",
        )}
      >
        <ScrollArea className="scrollbar-thin max-h-[250px] overflow-y-auto">
          <div className="space-y-1">{children}</div>
        </ScrollArea>
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn(
      "text-bodySm leading-bodySm text-soft bg-disabled px-4 py-2",
      className,
    )}
    {...props}
  />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

interface SelectItemBaseProps {
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  prefixStyles?: string;
  suffixStyles?: string;
}

interface SelectItemProps
  extends Omit<
      React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>,
      keyof SelectItemBaseProps
    >,
    SelectItemBaseProps {}

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  SelectItemProps
>(
  (
    {
      className,
      prefix,
      suffix,
      prefixStyles = "",
      suffixStyles = "",
      children,
      ...props
    },
    ref,
  ) => (
    <SelectPrimitive.Item
      ref={ref}
      className={cn(
        "hover:bg-hovered text-bodyMd data-[state='checked']:bg-hovered focus:bg-hovered relative flex w-full cursor-default select-none items-center rounded-md px-4 py-2 outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
        className,
      )}
      {...props}
    >
      {prefix && (
        <span
          className={cn(
            "-ml-1 mr-2 block [&_svg]:h-4 [&_svg]:w-4",
            prefixStyles,
          )}
        >
          {prefix}
        </span>
      )}
      <SelectPrimitive.ItemText className="text-default">
        {children}
      </SelectPrimitive.ItemText>
      {suffix && (
        <span
          className={cn("ml-2 block [&_svg]:h-3 [&_svg]:w-3", suffixStyles)}
        >
          {suffix}
        </span>
      )}
    </SelectPrimitive.Item>
  ),
);
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn("bg-active -mx-1 my-1 h-px", className)}
    {...props}
  />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
};
