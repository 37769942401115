"use client";

import { Database, Download, Star } from "@jengaicons/react";
import { Badge } from "@primitives/badge";
import { Grid, Row } from "@primitives/layout";
import { Text } from "@primitives/text";
import { motion } from "framer-motion";
import Image from "next/image";

const cards = [
  {
    title: "wikipedia",
    visibility: "Public",
    downloads: "34.4k",
    stars: "640",
    updated: "Updated 3 days ago",
  },
  {
    title: "bloomspeech",
    visibility: "Public",
    downloads: "3.3k",
    stars: "111",
    updated: "Updated 5 days ago",
  },
  {
    title: "openfeedback",
    visibility: "Public",
    downloads: "5k",
    stars: "11k",
    updated: "Updated 11 days ago",
  },
  {
    title: "music-genbox",
    visibility: "Public",
    downloads: "500",
    stars: "551",
    updated: "Updated 7 days ago",
  },
];

export function Datasets() {
  return (
    <div className="px-4 py-8 md:p-16">
      <Text
        color="soft"
        className="mb-8 max-w-[560px] md:mb-16"
        variant="bodyXl"
      >
        <Text color="default" as="span" variant="bodyXl" weight="semibold">
          Datasets.{" "}
        </Text>
        Explore, analyze and share datasets for Audio, Computer Vision, and
        Natural Language Processing (NLP) tasks.
      </Text>

      <Grid className="max-w-[564px] gap-7 md:grid-cols-2">
        {cards.map((i) => (
          <DatasetCard {...i} key={i.title} />
        ))}
      </Grid>
    </div>
  );
}

function DatasetCard(props: (typeof cards)[0]) {
  return (
    <div className="bg-default rounded-lg border p-4">
      <Row className="items-center justify-between">
        <Row className="gap-3">
          <Database size={20} />
          <Text weight="medium">{props.title}</Text>
        </Row>
        <Badge variant="outline" size="sm">
          {props.visibility}
        </Badge>
      </Row>

      <Row className="text-soft mt-4 gap-3">
        <Text variant="bodySm" color="soft" className="mr-auto">
          {props.updated}
        </Text>
        <Row className="gap-1">
          <Download size={12} />
          <Text variant="bodySm" color="soft">
            {props.downloads}
          </Text>
        </Row>
        <Row className="gap-1">
          <Star size={12} />
          <Text variant="bodySm" color="soft">
            {props.stars}
          </Text>
        </Row>
      </Row>

      <div className="relative mt-4 h-8 w-full overflow-hidden">
        <motion.div
          initial={{ clipPath: "inset(0 100% 0 0)" }}
          whileInView={{ clipPath: "inset(0 0 0 0)", opacity: 1 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          className="absolute left-0 top-0 h-full w-full"
        >
          <Image alt="" src="/website/datasets-graph.svg" fill />
        </motion.div>
      </div>
    </div>
  );
}
