"use client";

import {
  ChartPieSlice,
  Cube,
  Database,
  GithubLogoFill,
  Globe,
  HardDrives,
  JobsFill,
  ServiceFill,
  Star,
  Users,
} from "@jengaicons/react";
import { Avatar, AvatarFallback, AvatarImage } from "@primitives/avatar";
import { Badge } from "@primitives/badge";
import { Column, Grid, Row } from "@primitives/layout";
import { Text } from "@primitives/text";
import { motion } from "framer-motion";

export function MLPortfolio() {
  return (
    <div className="pl-4 pt-8 md:pl-16 md:pt-16">
      <Text
        color="soft"
        className="mb-8 max-w-[600px] md:mb-16"
        variant="bodyXl"
      >
        <Text color="default" as="span" variant="bodyXl" weight="semibold">
          ML Portfolio.{" "}
        </Text>
        Create a personalized ML profile, share your work globally, and
        collaborate with teams using Git.
      </Text>
      <motion.div
        initial={{
          translateX: 100,
        }}
        style={{
          opacity: 0,
        }}
        whileInView={{
          translateX: 0,
          opacity: 1,
        }}
        transition={{
          duration: 0.5,
        }}
        className="h-[404px] overflow-hidden"
      >
        <Grid className="bg-default grid-cols-[242px_1fr] gap-16 rounded-tl-lg border-l border-t p-10 pr-0">
          <Column>
            <div className="relative w-[173px]">
              <Avatar className="h-[173px] w-[173px] border">
                <AvatarImage src="/website/katara.webp" />
                <AvatarFallback />
              </Avatar>

              <Row className="bg-default absolute -bottom-3 left-1/2 -translate-x-1/2 divide-x rounded-lg border">
                <Row className="gap-2 px-4 py-2">
                  <ChartPieSlice size={16} />
                  <Text weight="medium">500</Text>
                </Row>
                <Row className="gap-2 px-4 py-2">
                  <Star size={16} />
                  <Text weight="medium">36</Text>
                </Row>
              </Row>
            </div>
            <Text className="mt-8" variant="heading2Xl" weight="semibold">
              Katara Murphy
            </Text>
            <Text className="mt-2" variant="bodyXl" color="soft">
              kataramurphy
            </Text>

            <Row className="mt-3 gap-2">
              <Users size={16} />
              <Text weight="medium">21k</Text>
              <Text color="soft">followers</Text>
              <Text>.</Text>
              <Text weight="medium">11k</Text>
              <Text color="soft">following</Text>
            </Row>

            <Column className="text-soft mt-3 gap-3">
              <Row className="gap-2">
                <Globe size={16} />
                <Text color="soft">https://kataramurphy.com</Text>
              </Row>
              <Row className="gap-2">
                <GithubLogoFill size={16} />
                <Text color="soft">https://github.com/kataramurphy</Text>
              </Row>
            </Column>
          </Column>

          <Column className="pt-10">
            <Text weight="semibold" variant="headingLg">
              Explore
            </Text>

            <Grid className="mt-3 grid-cols-[320px_320px] gap-5">
              {services.map((i) => (
                <ServiceCard {...i} key={i.title} />
              ))}
            </Grid>
          </Column>
        </Grid>
      </motion.div>
    </div>
  );
}

const services = [
  {
    title: "Models",
    count: "04",
    icon: <Cube size={16} />,
    desc: "Host machine learning models.",
  },
  {
    title: "Datasets",
    count: "04",
    icon: <Database size={16} />,
    desc: "Host datasets for training.",
  },
  {
    title: "Inference Endpoint",
    count: "02",
    icon: <ServiceFill size={16} />,
    desc: "Deploy models in production.",
  },
  {
    title: "Fine-Tuning",
    count: "02",
    icon: <JobsFill size={16} />,
    desc: "Train, fine-tune, or process data.",
  },
  {
    title: "AutoML",
    count: "02",
    icon: <JobsFill size={16} />,
    desc: "Train, fine-tune, or process data.",
  },
];

function ServiceCard(props: (typeof services)[0]) {
  return (
    <Row className="items-center gap-3 rounded-lg border p-5">
      <div className="bg-subdued shrink-0 rounded-lg border p-2">
        {props.icon}
      </div>
      <Column className="gap-1">
        <Row className="gap-2">
          <Text variant="headingMd" weight="semibold">
            {props.title}
          </Text>
          <Badge variant="outline" size="sm">
            {props.count}
          </Badge>
        </Row>
        <Text color="soft" className="whitespace-nowrap">
          {props.desc}
        </Text>
      </Column>
    </Row>
  );
}
