"use client";

import { ChevronDown, CircleHalfFill, Moon, Sun } from "@jengaicons/react";
import { Button } from "@primitives/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "@primitives/select";
import { useTheme } from "next-themes";
import React, { useEffect, useState } from "react";

export default function SwitchTheme() {
  const { theme, setTheme } = useTheme();

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (mounted)
    return (
      <div className="flex lg:justify-end">
        <Select value={theme} onValueChange={setTheme}>
          <SelectTrigger asChild>
            <Button
              className="max-w-36 justify-between rounded-full text-left"
              variant="trigger"
            >
              <div className="flex flex-row items-center gap-2 py-0">
                {theme === "light" ? (
                  <Sun />
                ) : theme === "dark" ? (
                  <Moon />
                ) : (
                  <CircleHalfFill />
                )}
                {theme}
              </div>
              <ChevronDown />
            </Button>
          </SelectTrigger>
          <SelectContent className="rounded-xl">
            <SelectItem value="system" className="rounded-xl">
              <div className="flex flex-row items-center gap-2">
                <CircleHalfFill size={16} /> System
              </div>
            </SelectItem>
            <SelectItem value="light" className="rounded-xl">
              <div className="flex flex-row items-center gap-2">
                <Sun size={16} /> Light
              </div>
            </SelectItem>
            <SelectItem value="dark" className="rounded-xl">
              <div className="flex flex-row items-center gap-2">
                <Moon size={16} /> Dark
              </div>
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
    );
}
